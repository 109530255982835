import Constants from '../../../utils/Constants';
import separator from '../../../assets/imgs/separator.svg';
import FadeInSection from '../../../utils/FadeInSection';
import { useEffect } from 'react';
import { CapitalizeFirstLetter } from '../../../utils/CapitalizeFirstLetter';
import Gallery from '../../home/components/gallery/Gallery';

const images = require.context(
  '../../../assets/imgs/accommodations/apartments',
  true
);
const imageList = images.keys().map((image: any) => images(image));

const Apartments = () => {
  FadeInSection();

  useEffect(() => {
    document.title = `${Constants.PROPERTY_NAME} - ${CapitalizeFirstLetter(
      Constants.APARTMENTS
    )}`;
  }, []);

  return (
    <div id={Constants.APARTMENTS} className='accommodations_wrapper'>
      <div className='accommodations_hero'>
        <img src={imageList[0]} alt={`${Constants.APARTMENTS} hero`} />
        <div className='accommodations_hero_title'>
          <h1>{CapitalizeFirstLetter(Constants.APARTMENTS)}</h1>
        </div>
      </div>
      <div className='accommodations_img'>
        <img src={separator} alt='separator' />
      </div>
      <div className='accommodations_container container'>
        <div className='accommodations_container_info'>
          <div className='accommodations_container_info_text fade_in'>
            Tastefully decorated, Korta studio apartments offer high-quality
            comfortable accommodation for two. These apartments consist of
            comfortable bed, kitchen area and bathroom with walk-in shower.
            <div className='accommodations_container_info_text_button'>
              <a
                href={Constants.bookNow}
                target='_blank'
                rel='noreferrer'
                className='opacity_transition_low'
              >
                <div>BOOK NOW</div>
              </a>
            </div>
          </div>
          <div className='accommodations_container_info_details'>
            <div className='fade_in'>
              <p>ENTERTAINMENT</p>
              <ul>
                <li>LCD TV</li>
              </ul>
              <p>ROOM FEATURES</p>
              <ul>
                <li>Wi-Fi</li>
                <li>Air conditioning</li>
                <li>Safe deposit box</li>
                <li>Wardrobe</li>
                <li>Reading light</li>
                <li>Electronic door lock</li>
                <li>Non-smoking</li>
                <li>Pets allowed</li>
              </ul>
            </div>
            <div className='fade_in'>
              <p>BED FEATURES</p>
              <ul>
                <li>Fine beddings</li>
                <li>Hypoallergenic pillows</li>
              </ul>
              <p>BATHROOM</p>
              <ul>
                <li>Walk-in shower</li>
                <li>Toiletries</li>
                <li>Towels</li>
                <li>Hairdryer</li>
              </ul>
              <p>DINING</p>
              <ul>
                <li>Kitchen</li>
                <li>Kitchen table and chairs</li>
                <li>Cutlery</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Gallery imageList={imageList} />
    </div>
  );
};

export default Apartments;
